import React from "react"

import Layout from "../components/layout"
import Snippet from "../components/snippet"

const Privacy = () => (
	<Layout
		meta={{
			title: "Impressum",
			description:
				"This website is provided by: Disy Informationssysteme GmbH Ludwig-Erhard-Allee 6. D - 76131 Karlsruhe phone +49 721 16006-000 e-mail info@disy.net",
			path: "/legal-notice",
		}}
	>
		<Snippet id="legal-notice" />
	</Layout>
)

export default Privacy
